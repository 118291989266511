import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import headerStyles from "./header.module.css"
import Headroom from "react-headroom"
import Img from "gatsby-image"

const Header = ({ siteTitle }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const data = useStaticQuery(graphql`
    query MenuImg {
      menu: file(relativePath: { eq: "images/menu.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 550) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const handleClick = e => {
    setMenuOpen(!menuOpen)
  }
  return (
    <Headroom onUnpin={() => setMenuOpen(false)}>
      <header
        id="sticky-header"
        style={{
          id: "sticky-header",
        }}
      >
        <div className="container sm-100">
          <div className="row">
            <div className="col-md-3 col-sm-2">
              <div className="logo text-upper">
                <h4>
                  <Link to="/">CHRISTIAN</Link>
                </h4>
              </div>
            </div>
            <div className="col-md-9 col-sm-10">
              <div className="menu-area hidden-xs">
                <nav>
                  <ul className="basic-menu clearfix">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/aboutme">about</Link>
                    </li>
                    <li>
                      <Link to="/portfolio">Portfolio</Link>
                    </li>

                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="basic-mobile-menu visible-xs">
                <div className="menu-bar">
                  <a
                    href="#"
                    className={headerStyles.menuIcon}
                    onClick={handleClick}
                  >
                    <Img fluid={data.menu.childImageSharp.fluid} />
                    {/*<Image filename="menu.png" />*/}
                  </a>
                  {menuOpen ? (
                    <nav id="mobile-nav" class={headerStyles.mobileNav}>
                      <ul>
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/aboutme">About Me</Link>
                        </li>
                        <li>
                          <Link to="/portfolio">Portfolio</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                      </ul>
                    </nav>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </Headroom>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
