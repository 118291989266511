import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import footerStyles from "./footer.module.css"

const Footer = ({ siteTitle }) => (
  <footer>
    <div className="basic-footer text-center ptb-90">
      <div className="container">
        <div className={footerStyles.footerLogo + " mb-30"}>
          <h3>
            <Link to="/">CHRISTIAN</Link>
          </h3>
        </div>
        <div className={footerStyles.socialIcon}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://projectsmax246.blogspot.co.uk/"
          >
            <i className={footerStyles.ionSocialCodepen}></i>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/christian.bianchini/"
          >
            <i className={footerStyles.ionSocialInstagram}></i>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/max246"
          >
            <i className={footerStyles.ionSocialGithub}></i>
          </a>
        </div>
        <div className={footerStyles.footerMenu + " mt-30"}>
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/portfolio">Portfolio</Link>
              </li>
              <li>
                <Link to="/aboutme">About</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="copyright mt-20">
          <p>
            All copyright © reserved by <Link to="/">Christian Bianchini</Link>{" "}
            2020
          </p>
          <meta
            name="thanks"
            content="VGhhbmsgeW91IHZlcnkgbXVjaCB0byBnb256eSB0byBoYXZlIGhlbHBlZCBtZSB0byBtYWtlIHRoaXMgd2Vic2l0ZQ=="
          ></meta>
        </div>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
